
import { toRefs, nextTick, defineComponent, onActivated, onBeforeUnmount, onDeactivated, onMounted, PropType, watch, ref, unref } from 'vue';
import resize from '@/components/charts/mixins/resize';
import { init, EChartsOption } from 'echarts';
import { getDashboardRevenueTrend, DashboardRevenueTrendResponse } from '@/services/api';

const DASHBOARD_DATA: DashboardRevenueTrendResponse['data'] = {
  date: [],
  newRevenue: [],
  renewRevenue: [],
  usRenewRevenue: [],
  totalRevenue: []
};
export default defineComponent({
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%',
      required: true
    },
    height: {
      type: String,
      default: '350px',
      required: true
    },
    /**
     * select_date
     * @type Array [endDate,startDate]
     */
    selectDate: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    const {
      mounted,
      chart,
      beforeDestroy,
      activated,
      deactivated
    } = resize();

    const dashboardData = ref({ ...DASHBOARD_DATA });
    const getDashboardData = async(selectDate?: any[]) => {
      const query = {
        startDate: selectDate[1],
        endDate: selectDate[0]
      };
      const { data } = await getDashboardRevenueTrend({ query });
      dashboardData.value = data;
    };

    watch(() => props.selectDate, (selectDate) => {
      getDashboardData(selectDate);
    }, { deep: true });

    const setOptions = () => {
      const unwarp = (obj) => obj && (obj.__v_raw || obj.valueOf() || obj);
      unwarp(chart.value).setOption({
        xAxis: {
          data: dashboardData.value.date,
          boundaryGap: false,
          axisTick: {
            show: false
          }
        },
        grid: {
          left: 12,
          right: 40,
          bottom: 20,
          top: 30,
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          // axisPointer: {
          //   type: 'cross'
          // },
          padding: 8
        },
        yAxis: {
          axisTick: {
            show: false
          }
        },
        legend: {
          data: ['New Revenue', 'Renew Revenue', 'US Renew Revenue', 'Total Revenue']
        },
        series: [
          {
            name: 'New Revenue',
            itemStyle: {
              lineStyle: {
                width: 2
              }
            },
            smooth: false,
            type: 'line',
            data: dashboardData.value.newRevenue || [],
            animationDuration: 1000,
            animationEasing: 'cubicInOut'
          },
          {
            name: 'Renew Revenue',
            itemStyle: {
              lineStyle: {
                width: 2
              }
            },
            smooth: false,
            type: 'line',
            data: dashboardData.value.renewRevenue || [],
            animationDuration: 1000,
            animationEasing: 'cubicInOut'
          },
          {
            name: 'US Renew Revenue',
            itemStyle: {
              lineStyle: {
                width: 2
              }
            },
            smooth: false,
            type: 'line',
            data: dashboardData.value.usRenewRevenue || [],
            animationDuration: 1000,
            animationEasing: 'cubicInOut'
          },
          {
            name: 'Total Revenue',
            itemStyle: {
              lineStyle: {
                width: 2
              }
            },
            smooth: false,
            type: 'line',
            data: dashboardData.value.totalRevenue || [],
            animationDuration: 1000,
            animationEasing: 'cubicInOut'
          }
        ]
      } as EChartsOption);
    };

    watch(dashboardData, () => {
      setOptions();
    });

    const initChart = () => {
      const lineChart = init(document.getElementById('homeLineCharts') as HTMLDivElement, 'macarons');
      chart.value = lineChart;
    };

    onMounted(() => {
      mounted();
      // getDashboardData();
      nextTick(() => {
        initChart();
        setOptions();
      });
    });

    onBeforeUnmount(() => {
      beforeDestroy();
      if (!chart.value) {
        return;
      }
      chart.value.dispose();
      chart.value = null;
    });

    onActivated(() => {
      activated();
    });

    onDeactivated(() => {
      deactivated();
    });

    return {

    };
  }
});
