
import { nextTick, defineComponent, onActivated, onBeforeUnmount, onDeactivated, onMounted, PropType, watch, ref } from 'vue';
import resize from '@/components/charts/mixins/resize';
import { init, EChartsOption } from 'echarts';

import { getDashboardMemberTrend, DashboardMemberTrendResponse } from '@/services/api';

const DASHBOARD_DATA: DashboardMemberTrendResponse['data'] = {
  date: [],
  newCount: [],
  renewCount: [],
  usRenewCount: [],
  totalCount: []
};
export default defineComponent({
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%',
      required: true
    },
    height: {
      type: String,
      default: '350px',
      required: true
    },
    /**
     * select_date
     * @type Array [endDate,startDate]
     */
    selectDate: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    const {
      mounted,
      chart,
      beforeDestroy,
      activated,
      deactivated
    } = resize();

    const dashboardData = ref({ ...DASHBOARD_DATA });
    const getDashboardData = async(selectDate?: any[]) => {
      const query = {
        startDate: selectDate[1],
        endDate: selectDate[0]
      };
      const { data } = await getDashboardMemberTrend({ query });
      dashboardData.value = data;
    };

    watch(() => props.selectDate, (selectDate) => {
      getDashboardData(selectDate);
    }, { deep: true });

    const setOptions = () => {
      const unwarp = (obj) => obj && (obj.__v_raw || obj.valueOf() || obj);
      unwarp(chart.value).setOption({
        xAxis: {
          data: dashboardData.value.date,
          boundaryGap: false,
          axisTick: {
            show: false
          }
        },
        grid: {
          left: 12,
          right: 40,
          bottom: 20,
          top: 30,
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // type: 'cross'
          },
          padding: 8
        },
        yAxis: {
          axisTick: {
            show: false
          }
        },
        legend: {
          data: ['New Member', 'Renew Member', 'US Renew Member', 'Total Member']
        },
        series: [
          {
            name: 'New Member',
            smooth: false,
            type: 'line',
            itemStyle: {
              lineStyle: {
                width: 2
              }
            },
            data: dashboardData.value.newCount || [],
            animationDuration: 1000,
            animationEasing: 'quadraticOut'
          },
          {
            name: 'Renew Member',
            smooth: false,
            type: 'line',
            itemStyle: {
              lineStyle: {
                width: 2
              }
            },
            data: dashboardData.value.renewCount || [],
            animationDuration: 1000,
            animationEasing: 'quadraticOut'
          },
          {
            name: 'US Renew Member',
            smooth: false,
            type: 'line',
            itemStyle: {
              lineStyle: {
                width: 2
              }
            },
            data: dashboardData.value.usRenewCount || [],
            animationDuration: 1000,
            animationEasing: 'quadraticOut'
          },
          {
            name: 'Total Member',
            smooth: false,
            type: 'line',
            itemStyle: {
              lineStyle: {
                width: 2
              }
            },
            data: dashboardData.value.totalCount || [],
            animationDuration: 1000,
            animationEasing: 'quadraticOut'
          }
        ]
      } as EChartsOption);
    };

    watch(dashboardData, () => {
      setOptions();
    });

    const initChart = () => {
      const lineChart = init(document.getElementById('homeLineChartsMember') as HTMLDivElement, 'macarons');
      chart.value = lineChart;
    };

    onMounted(() => {
      mounted();
      // getDashboardData();
      nextTick(() => {
        initChart();
        setOptions();
      });
    });

    onBeforeUnmount(() => {
      beforeDestroy();
      if (!chart.value) {
        return;
      }
      chart.value.dispose();
      chart.value = null;
    });

    onActivated(() => {
      activated();
    });

    onDeactivated(() => {
      deactivated();
    });

    return {};
  }
});
