
import { defineComponent, ref, unref, toRefs, onMounted } from 'vue';
import { CountTo } from 'vue3-count-to';
import { getDashboardRevenueGather, DashboardRevenueGatherResponse, OrderSuccessRatioResponse } from '@/services/api';
export default defineComponent({
  components: {
    CountTo
  },
  setup() {
    const revenueGather = ref<DashboardRevenueGatherResponse['data']>({});
    const orderSuccessRatio = ref<OrderSuccessRatioResponse['data']>({});

    const fetchDashboardRevenueGather = async() => {
      const { data } = await getDashboardRevenueGather();
      revenueGather.value = data;
    };

    onMounted(() => {
      fetchDashboardRevenueGather();
    });

    return {
      revenueGather,
      orderSuccessRatio
    };
  }
});
