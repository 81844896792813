import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createBlock(_component_el_select, {
    modelValue: $setup.selectValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.selectValue) = $event)),
    placeholder: "Select",
    size: "small",
    onChange: $setup.handleSelect
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.daysOptions, (item) => {
        return (_openBlock(), _createBlock(_component_el_option, {
          key: item.value,
          label: item.label,
          value: item.value
        }, null, 8, ["label", "value"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["modelValue", "onChange"]))
}