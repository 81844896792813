
import {
  defineComponent,
  onActivated,
  onBeforeUnmount,
  onDeactivated,
  onMounted,
  nextTick
} from 'vue';
import resize from '@/components/charts/mixins/resize';
import { init, EChartsOption } from 'echarts';

import { getDashboardDevices } from '@/services/api';
import dayjs from 'dayjs';

export default defineComponent({
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%',
      required: true
    },
    height: {
      type: String,
      default: '300px',
      required: true
    }
  },
  setup() {
    const { mounted, chart, beforeDestroy, activated, deactivated } = resize();

    const now = dayjs().format('YYYY.MM.DD');
    const sixDaysBefore = dayjs().subtract(6, 'day').format('YYYY.MM.DD');

    const setOptions = async() => {
      const { data } = await getDashboardDevices({ query: { type: 'subscribers' } });

      chart.value.setOption({
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
          orient: 'vertical',
          left: 20
        },
        series: [
          {
            color: ['#435EBE', '#5DDAB4', '#9694FF', '#FF7976', '#57CAEB'],
            name: 'WEEKLY WRITE ARTICLES',
            type: 'pie',
            data: data || [],
            animationEasing: 'cubicInOut',
            animationDuration: 2000
          }
        ]
      } as EChartsOption);
    };

    const initChart = async() => {
      const pieChart = init(
        document.getElementById('homePieChartsSubscriber') as HTMLDivElement,
        'macarons'
      );
      chart.value = pieChart;
    };

    onMounted(() => {
      mounted();
      nextTick(() => {
        initChart();
        setOptions();
      });
    });

    onBeforeUnmount(() => {
      // if (!chart.value) {
      //   return
      // }
      // chart.value.dispose()
      // chart.value = null
      beforeDestroy();
    });

    onActivated(() => {
      activated();
    });

    onDeactivated(() => {
      deactivated();
    });

    return {
      now,
      sixDaysBefore
    };
  }
});
