
import { nextTick, defineComponent, onActivated, onBeforeUnmount, onDeactivated, onMounted, PropType, watch, ref, toRefs } from 'vue';
import resize from '@/components/charts/mixins/resize';
import { init, EChartsOption } from 'echarts';
import { getDashboardRealTimeRevenues, DashboardRealTimeRevenuesResponse } from '@/services/api';

const DASHBOARD_DATA: DashboardRealTimeRevenuesResponse['data'] = {
  date: [],
  value: {
    channelRevenue: [],
    directRevenue: [],
    totalRevenue: []
  }
};

export default defineComponent({
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%',
      required: true
    },
    height: {
      type: String,
      default: '350px',
      required: true
    }
  },
  setup(props) {
    const {
      mounted,
      chart,
      beforeDestroy,
      activated,
      deactivated
    } = resize();

    const dashboardData = ref({ ...DASHBOARD_DATA });
    const getDashboardData = async() => {
      const { data } = await getDashboardRealTimeRevenues();
      dashboardData.value = data;
    };

    const setOptions = () => {
      const unwarp = (obj) => obj && (obj.__v_raw || obj.valueOf() || obj);
      unwarp(chart.value).setOption({
        xAxis: {
          data: dashboardData.value.date,
          boundaryGap: false,
          axisTick: {
            show: false
          }
        },
        grid: {
          left: 20,
          right: 20,
          bottom: 20,
          top: 60,
          containLabel: true
        },
        tooltip: {
          trigger: 'axis'
          // axisPointer: {
          //   type: 'cross'
          // },
        },
        yAxis: {
          axisTick: {
            show: false
          }
        },
        legend: {
          data: [
            'Total revenue',
            'Channel revenue',
            'Direct_revenue'
          ]
        },
        series: [
          {
            name: 'Total revenue',
            itemStyle: {
              color: '#5CADAD',
              lineStyle: {
                color: '#5CADAD',
                width: 2
              }
            },
            smooth: false,
            type: 'line',
            data: dashboardData.value.value.totalRevenue,
            animationDuration: 1000,
            animationEasing: 'cubicInOut'
          },
          {
            name: 'Channel revenue',
            itemStyle: {
              color: '#8080C0',
              lineStyle: {
                color: '#8080C0',
                width: 2
              }
            },
            smooth: false,
            type: 'line',
            data: dashboardData.value.value.channelRevenue,
            animationDuration: 1000,
            animationEasing: 'cubicInOut'
          },
          {
            name: 'Direct_revenue',
            itemStyle: {
              color: '#AE57A4',
              lineStyle: {
                color: '#AE57A4',
                width: 2
              }
            },
            smooth: false,
            type: 'line',
            data: dashboardData.value.value.directRevenue,
            animationDuration: 1000,
            animationEasing: 'cubicInOut'
          }
        ]
      } as EChartsOption);
    };

    watch(dashboardData, () => {
      setOptions();
    });

    const initChart = () => {
      const lineChart = init(document.getElementById('homeLineChartsRealTimeRevenue') as HTMLDivElement, 'macarons');
      chart.value = lineChart;
    };

    onMounted(() => {
      mounted();
      getDashboardData();
      nextTick(() => {
        initChart();
        setOptions();
      });
    });

    onBeforeUnmount(() => {
      beforeDestroy();
      if (!chart.value) {
        return;
      }
      chart.value.dispose();
      chart.value = null;
    });

    onActivated(() => {
      activated();
    });

    onDeactivated(() => {
      deactivated();
    });

    return {
    };
  }
});
