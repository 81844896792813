
import { nextTick, defineComponent, onActivated, onBeforeUnmount, onDeactivated, onMounted, PropType, watch, ref } from 'vue';
import resize from '@/components/charts/mixins/resize';
import { init, EChartsOption } from 'echarts';
import { getDashboardRealTimeUsers, DashboardRealTimeUsersResponse } from '@/services/api';

const DASHBOARD_DATA: DashboardRealTimeUsersResponse['data'] = {
  date: [],
  value: {
    totalVisitorsCount: [], // 總訪客
    channelVisitorsCount: [], // 渠道訪客
    directVisitorsCount: [], // 自來量訪客
    totalSubscribersCount: [], // 總會員
    channelSubscribersCount: [], // 渠道會員
    directSubscribersCount: [] // 自來量會員
  }
};

export default defineComponent({
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%',
      required: true
    },
    height: {
      type: String,
      default: '350px',
      required: true
    }
  },
  setup(props) {
    const {
      mounted,
      chart,
      beforeDestroy,
      activated,
      deactivated
    } = resize();

    const dashboardData = ref({ ...DASHBOARD_DATA });
    const getDashboardData = async() => {
      const { data } = await getDashboardRealTimeUsers();
      dashboardData.value = data;
    };
    const setOptions = async() => {
      const unwarp = (obj) => obj && (obj.__v_raw || obj.valueOf() || obj);
      unwarp(chart.value).setOption({
        xAxis: {
          data: dashboardData.value.date,
          boundaryGap: false,
          axisTick: {
            show: false
          }
        },
        grid: {
          left: 20,
          right: 20,
          bottom: 20,
          top: 60,
          containLabel: true
        },
        tooltip: {
          trigger: 'axis'
        },
        yAxis: {
          axisTick: {
            show: false
          }
        },
        legend: {
          data: [
            'Total visitors',
            'Total subscribers',
            'Channel visitors',
            'Channel subscribers',
            'Organic visitors',
            'Organic subscribers'
          ]
        },
        series: [
          {
            name: 'Total visitors',
            itemStyle: {
              color: '#FF005A',
              lineStyle: {
                color: '#FF005A',
                width: 2
              }
            },
            smooth: false,
            type: 'line',
            data: dashboardData.value.value.totalVisitorsCount,
            animationDuration: 1000,
            animationEasing: 'cubicInOut'
          },
          {
            name: 'Total subscribers',
            itemStyle: {
              color: '#6F00D2',
              lineStyle: {
                color: '#6F00D2',
                width: 2
              }
            },
            smooth: false,
            type: 'line',
            data: dashboardData.value.value.totalSubscribersCount,
            animationDuration: 1000,
            animationEasing: 'cubicInOut'
          },
          {
            name: 'Channel visitors',
            itemStyle: {
              color: '#0000E3',
              lineStyle: {
                color: '#0000E3',
                width: 2
              }
            },
            smooth: false,
            type: 'line',
            data: dashboardData.value.value.channelVisitorsCount,
            animationDuration: 1000,
            animationEasing: 'cubicInOut'
          },
          {
            name: 'Channel subscribers',
            itemStyle: {
              color: '#00AEAE',
              lineStyle: {
                color: '#00AEAE',
                width: 2
              }
            },
            smooth: false,
            type: 'line',
            data: dashboardData.value.value.channelSubscribersCount,
            animationDuration: 1000,
            animationEasing: 'cubicInOut'
          },
          {
            name: 'Organic visitors',
            itemStyle: {
              color: '#EA7500',
              lineStyle: {
                color: '#EA7500',
                width: 2
              }
            },
            smooth: false,
            type: 'line',
            data: dashboardData.value.value.directVisitorsCount,
            animationDuration: 1000,
            animationEasing: 'cubicInOut'
          },
          {
            name: 'Organic subscribers',
            itemStyle: {
              color: '#AD5A5A',
              lineStyle: {
                color: '#AD5A5A',
                width: 2
              }
            },
            smooth: false,
            type: 'line',
            data: dashboardData.value.value.directSubscribersCount,
            animationDuration: 1000,
            animationEasing: 'cubicInOut'
          }
        ]
      }as EChartsOption);
    };

    watch(dashboardData, () => {
      setOptions();
    });
    const initChart = () => {
      const lineChart = init(document.getElementById('homeLineChartsRealTimeUser') as HTMLDivElement, 'macarons');
      chart.value = lineChart;
    };

    onMounted(() => {
      mounted();
      getDashboardData();
      nextTick(() => {
        initChart();
        setOptions();
      });
    });

    onBeforeUnmount(() => {
      beforeDestroy();
      if (!chart.value) {
        return;
      }
      chart.value.dispose();
      chart.value = null;
    });

    onActivated(() => {
      activated();
    });

    onDeactivated(() => {
      deactivated();
    });

    return {
    };
  }
});
